import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import {
  AddAdminRoleResponse,
  CreateUser,
  InviteAdmin,
  InviteAdminResponse,
  UpdateAdminResponse,
  ViewAdminInfoResponse,
  ViewAdminListResponse,
} from '@core/models/admin/admin-management';
import { Pagination } from '@core/models/pagination.model';
import { TOKEN } from 'app/app.constants';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminManagementService {
  private http = inject(HttpClient);
  private loadingService = inject(LoadingService);

  BASE_URL = `${environment.ADMIN_BASE_URL}/v1/admin`;

  constructor() {}

  inviteAdmin(adminInfo: InviteAdmin): Observable<InviteAdminResponse> {
    const uniqueStateUrl = 'invite-admin';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<InviteAdminResponse>(`${this.BASE_URL}/invite-admin`, adminInfo)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  resendInvite(adminId: string): Observable<unknown> {
    return this.http.post(`${this.BASE_URL}/resend-admin-invite`, {
      admin_id: adminId,
    });
  }

  createUser(userInfo: CreateUser) {
    return this.http.post(this.BASE_URL, userInfo);
  }

  manageAdminStatus(adminId: string): Observable<unknown> {
    return this.http.patch(`${this.BASE_URL}/manage-admin-status`, {
      admin_id: adminId,
    });
  }

  deleteAdmin(adminId: string): Observable<unknown> {
    return this.http.post(`${this.BASE_URL}/delete-admin`, { id: adminId });
  }

  viewAdminInfo(adminId: string): Observable<ViewAdminInfoResponse> {
    const uniqueStateUrl = 'view-admin-info';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<ViewAdminInfoResponse>(
        `${this.BASE_URL}/view-admin-info?id=${adminId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateAdminInfo(payload: {
    id: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    email: string;
  }): Observable<UpdateAdminResponse> {
    const uniqueStateUrl = 'update-admin-profile-data';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<UpdateAdminResponse>(
        `${this.BASE_URL}/update-admin-profile-data`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  viewAdminList(
    data: Pagination = new Pagination()
  ): Observable<ViewAdminListResponse> {
    const uniqueStateUrl = 'view-admin-list';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<ViewAdminListResponse>(
        `${this.BASE_URL}/view-admin-list?take=${data.size}&order=${data.order}&skip=${data.skip}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  deactivateAdmin(
    adminId: string
  ): Observable<{ status: string; message: string }> {
    return this.http.patch<{ status: string; message: string }>(
      `${this.BASE_URL}/manage-admin-status`,
      { admin_id: adminId }
    );
  }

  fitlerAdminList(queryParams: string): Observable<ViewAdminListResponse> {
    return this.http.get<ViewAdminListResponse>(`${this.BASE_URL}/filter-admin-list?${queryParams}`);
  }

  viewOrderInfo(orderId: string) {
    return this.http.get(`${this.BASE_URL}/view-order-info/${orderId}`);
  }
  viewOrderList(data: Pagination = new Pagination()): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/view-orders-list?take=${data.size}&order=${data.order}&skip=${data.skip}`
    );
  }

  addRoleToUser(payload: {
    admin_id: string;
    role_id: string;
  }): Observable<AddAdminRoleResponse> {
    const uniqueStateUrl = 'add-role-to-user';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<AddAdminRoleResponse>(`${this.BASE_URL}/add-role-to-user`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  removeRoleFromUser(payload: { id: string; admin_id: string }) {
    const uniqueStateUrl = 'remove-role-from-user';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(`${this.BASE_URL}/remove-role-from-user`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  resetAdminPassword(payload: {
    current_password: string;
    new_password: string;
  }) {
    const uniqueStateUrl = 'update-password';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(`${this.BASE_URL}/update-password`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  isLoggedIn() {
    return true;
  }
}
